<template>
    <div v-if="shouldShow()" class="continueLearningNav">

        <div class="continue-learning-top">
            <div class="continue-learning-top__title continue-learning-top__mg">
                <h3>{{ $t("learning.title") }}</h3>
            </div>

            <div>
                <CommonControlsCarousel @prev="prev(uniqueId)" @next="next(uniqueId)" />
            </div>
        </div>

        <Swiper :ref="`${uniqueId}`" :options="swiperOption">
            <template>
                <SwiperSlide  v-for="item in modules" :key="item.id">
                    <div class="continue-learning-cards">
                        <div class="continue-learning-card">

                            <div class="continue-learning-card__image" @click="openCourse(item.url)">
                                <img v-if="item.image" :src="item.image">
                                <div v-else class="continue-learning-card__image"></div>

                                <div class="continue-learning-card__icon">
                                    <IconSax v-if="item.icon != 'play'" :name="item.icon" type="bold" size="1.4rem" />
                                    <template v-else>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 40 40" fill="white">
                                        <circle cx="20" cy="20" r="20"/>
                                        <path d="M26.1992 18.4691C27.5326 19.2389 27.5326 21.1634 26.1992 21.9332L18.6992 26.2634C17.3659 27.0332 15.6992 26.0709 15.6992 24.5313L15.6992 15.871C15.6992 14.3314 17.3659 13.3692 18.6992 14.139L26.1992 18.4691Z" fill="white"/>
                                        </svg>
                                    </template>
                                </div>

                            </div>

                            <div class="continue-learning-card__description">
                                <p>{{ $t("learning.title_course") }} {{  item.course }}</p>
                                <p>{{ $t("learning.title_module") }} {{  item.module }}</p>

                                <h3 class="continue-learning-card__title">{{  item.title }}</h3>

                                <div class="continue-learning-card__nav-content">
                                    <div class="continue-learning-card__nav-header">
                                        <div class="continue-learning-card__nav-header__progress">
                                        <span class="continue-learning-card__nav-header__progress__label"
                                            > {{ $t("my_courses.progress") }} {{ item.progress }}%</span
                                        >
                                        <IconSax name="medal-star" />
                                        </div>
                                        <ProgressBar :percentage="item.progress" />
                                    </div>
                                </div>

                                <div class="continue-learning-card__action">
                                    <ButtonPlay size="sm" type="outline" @click="openCourse(item.url)">
                                        <span class="adminBar__actions--textt">
                                            {{ $t("learning.button_class") }}
                                        </span>
                                        <span class="continue-learning-card__action__icon"><IconSax name="playright"/></span>
                                    </ButtonPlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </template>
        </Swiper>
    </div>
</template>

<script>  

import { Carousel, Slide } from "vue-carousel";
const serviceCourse = CourseService.build();
import CourseService from "@/services/resources/CourseService";
import { mapGetters } from "vuex";
import { textLimit } from "@/utils/strings";

export default {
    components: {
        Carousel,
        Slide,
        CommonControlsCarousel: require("@/components/common/carousel/ControlsCarousel.vue").default,
        CommonBoxModule: require("@/components/common/box/Module.vue").default,
        ProgressBar: () => import("@/components/common/progressBar/ProgressBar.vue")
    },
    computed: {
        ...mapGetters({
            getSettings: "config/getSettings",
            getMe: "auth/getMe"
        }),
    },
    data() {
        return {
            uniqueId: null,
            limit_character: 35,
            swiperOption: {
                direction: "horizontal",
                loop: false,
                height: 60,
                spaceBetween: 20,
                slidesPerView: 1,
                navigation: true,
                centeredSlides: false,
                resizeReInit: true,
                watchOverflow: true
            },
            modules: []
        };
    },
    mounted() {
        this.uniqueId = this.createUniqueId();
        serviceCourse
        .read(
            this.getMe.member.id + "/whereStopped"
        ).then((data) => {
            this.setModules(data)
        })
    },
    methods: {
        textLimit,
        setModules(data){
            if(data.length > 0){
                let courses = data.filter(course => course.modules?.some(module => module.currentModuleLessons !== null));
                var result = [];
                courses.map((course) => {
                    course.modules.map((module) => {
                        if(module.currentModuleLessons){
                            let image = (module.currentModuleLessons.custom_thumb) ? module.currentModuleLessons.custom_thumb.cdn_url : course.cover.cdn_url;
                            if(module.lessons_completed_count > 0){
                                let url = `/curso/${course.id}/modulo/${module.id}/aula/${module.currentModuleLessons.id}`;
                                result.push({
                                    id: course.id,
                                    title: this.textLimit(module.currentModuleLessons.title, this.limit_character),
                                    course: this.textLimit(course.title,this.limit_character),
                                    module: this.textLimit(module.title,this.limit_character),
                                    image: image,
                                    icon: this.getLessonIcon(module.currentModuleLessons.mediaType),
                                    progress: course.percentage_complete,
                                    url: url
                                });
                            }
                        }
                    });
                })

                let filtered = this.filterById(result);

                if(result.length > 0 && filtered){
                    filtered.map(filter =>  this.modules.push(filter));
                }
            }
        },
        filterById(datas) {
            const ids = {};
            return datas.filter(data => !ids[data.id] && (ids[data.id] = true));
        },
        createUniqueId() {
            const timestamp = new Date().getTime();
            const randomNumber = Math.floor(Math.random() * 10000);
            const uniqueId = `${timestamp}_${randomNumber}`;
            return uniqueId;
        },
        prev(name) {
            this.$refs[name].$swiper.slidePrev();
        },
        next(name) {
            this.$refs[name].$swiper.slideNext();
        },
        shouldShow(){
            const { learning_progression } = this.getSettings
            return this.modules.length > 0 && learning_progression && this.getMe.member.role === "student";
        },
        openCourse(url){
            this.$router.push(url);
        },
        getLessonIcon(mediaType) {
        
            const mediaIconMap = {
                "youtube": "play",
                "vimeo": "play",
                "panda": "play",
                "voomptube": "play",
                "audio": "microphone",
                "text": "clipboard-text",
                "quiz": "clipboard-tick",
            };

            return mediaIconMap[mediaType] || "play";
        }
    }
}
</script>


<style lang="scss" scoped>

[data-theme="dark"] {
    .continue-learning-top__title h3, .continue-learning-card__title{
        color: #ffffff;
    }

    .continue-learning-card{
        background-color: #1E1E20;
    }

    .continue-learning-card__image{
        background-color: var(--neutral-black);
    }
}

.continue-learning-card__icon i{
    background: var(--maincolor);
    border-radius: 50%;
    padding: 1rem;
}

.continue-learning-card__icon circle{
    fill: var(--maincolor);
}


.continue-learning-card__icon i::before{
    font-size: 25px;
    color: #fff;
}
.continueLearningNav {
    position: relative;
    width: 100%;
    height: 340px;
    padding-top: 16px;
    gap: 20px;
    border-radius: 8px;
    display: flex;
    margin-bottom: 3em;
    flex-direction: column;
    overflow: hidden;
}

.continue-learning-top{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    height: 40px;
}
.continue-learning-top__title h3{
    font-size: 20px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    color: var(--opposite-color);
}

.continue-learning-top__mg h3{
    margin-top: 0.5rem;
}

.continue-learning-card{
    position: relative;
    display: flex;
    width: 100%;
    height: 248px;
    flex-wrap: nowrap;
    align-content: center;
    background-color: var(--neutral-gray-80);
    padding: 24px;
    gap: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
}

.continue-learning-card__image{
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    background-color: #000000;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    height: 200px;
}

.continue-learning-card__image img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    z-index: 2;
    border-radius: 8px;
    filter: brightness(0.6)
}
.continue-learning-card__description{
    width: 100%;
    padding-right: 20px;
    border-radius: 8px;
}

.continue-learning-card__description p, .continue-learning-card__description div p{
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 3px;
    color: #9E9E9E;
}

.continue-learning-card__title{
    margin-bottom: 20px !important;
    word-wrap: break-word;
    color: var(--text-card-continue-learning);
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
}

.continue-learning-card__action{
    display: flex;
    flex-wrap: wrap;
    color: var(--maincolor);
    align-items: center;
    gap: 5px;


}

.continue-learning-card__action button{
    &:hover {
        ::v-deep .svgCss {
            svg > g> rect {
            fill: var(--neutral-white) !important;
            }
        }
    }
}

.continue-learning-card__icon{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999999;
    transform: translate(-50%, -50%);
    ::v-deep .svgCss {
        svg {
            width: 2.5rem;
            height: 2.5rem;
        }

        svg circle {
            fill: var(--maincolor);
        }
    }
}

.continue-learning-cards{
    display: flex;
    gap: 35px;
}

.swiper-slide {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.swiper-wrapper {
    display: flex;
    gap: 24px;
}

.swiper-container{
    margin: 0;
}

.continue-learning-card__nav-header__progress__label {
    color: #9E9E9E;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
    text-align: left;
}

.continue-learning-card__nav-content {
  display: flex;
  z-index: 999;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  flex-shrink: 0;
  width: 100%;
  overflow: auto;
}

.progress {
  height: 0.25rem;
  align-self: stretch;
}

.continue-learning-card__nav-header {
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5px;
  align-self: stretch;
}

.nav__header__title {
  align-self: stretch;
  overflow: hidden;
  color: var(--fontcolor);
  text-overflow: ellipsis;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.progress-container{
    border-radius: 5px;
}

::v-deep .continue-learning-card__nav-header__progress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;

  svg {
    height: 1rem;
    width: 1rem;
  }

  svg path {
    fill: var(--maincolor);
  }
}

@media screen and (max-width: 500px) {

    .continue-learning-card{
        flex-wrap: wrap;
        height: 357px;
    }

    .continueLearningNav {
        height: 450px;
    }

    .continue-learning-card__icon{
        top: 50;
        left: 50%;
    }

    .continue-learning-card__nav-header {
        padding-bottom: 16px;
    }

    .continue-learning-card__image img, .continue-learning-card__image{
        height: 130px;
    }
}

</style>